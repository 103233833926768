var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"id":"modal-medicine","size":"xl","title":"Pilih Obat","hide-footer":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-9"},[_c('Table',{attrs:{"purpose":"modal","modalPurpose":"modal","isHospitalTransaction":_vm.isHospitalTransaction,"selectedItem":_vm.selectedItem,"checkbox":_vm.checkbox,"isSortcut":false,"isAbjad":true,"secondPurpose":_vm.secondPurpose},on:{"chooseMedicine":_vm.chosenMedicine,"packageMedicine":_vm.packageMedicine}})],1),_c('div',{staticClass:"col-md-3"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.selectedItem,"fields":_vm.fields,"responsive":""},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
                width:
                  field.key === 'actions'
                    ? _vm.purpose == 'master'
                      ? '17%'
                      : ''
                    : '',
                width: field.key == 'generic_name' ? '15%' : '',
              })})})}},{key:"cell(name)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.name))])]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-danger mr-1",staticStyle:{"padding":"5px !important"},attrs:{"size":"sm","title":"Hapus"},on:{"click":function($event){return _vm.deleteData(data.item.id)}}},[_c('i',{staticClass:"fas fa-trash px-0"})])]}}])})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }