<template>
  <div>
     <div class="d-flex justify-content-start align-items-center mt-5" >
      <button
        class="btn btn-warning mr-1"
        v-b-tooltip.hover
        title="Download Excel"
        @click="btnExcelOnClick"
        
      >
        <i class="fas fa-file-excel px-0"></i>
      </button>
     
    </div>
    <!-- <span class="mt-3"><b>Total Obat: {{total_medicine}}</b></span> -->
    <!-- Table -->
    <b-table
      striped
      hover
      :items="dataModal"
      :fields="fields"
      responsive
    >
    <!-- :style="{ width: field.key === 'actions' ? purpose == 'master' ? '17%' : '' : '' , width: field.key === 'responsible_doctor' ? '10%' : '' }" -->
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          
          :style="{ width: field.key === 'actions' ? purpose == 'master' ? '17%' : '' : '', width: field.key == 'generic_name' ? '15%' : '' }"
        />
      </template>
      <template #cell(batch)="data">
        <span v-html="data.item.batch"></span>
      </template>
      <template #cell(is_ecommerce_product)="data">
        <template v-if="btn == true">
          <b-button
            size="sm"
            v-b-tooltip.hover
            v-if="data.item.is_ecommerce_product == 1"
            title="Aktif"
            @click="setActive(data.item.id)"
            class="mr-1 btn-light-success text-success"
          ><i class="fas fa-check px-0"></i></b-button>
          <b-button
            size="sm"
            v-b-tooltip.hover
            v-if="data.item.is_ecommerce_product == 0"
            title="Non Aktif"
            @click="setActive(data.item.id)"
            class="mr-1 btn-light-danger text-danger"
          ><i class="fas fa-times px-0"></i></b-button>
        </template>
        <template v-if="btn == false">
          <b-button
            size="sm"
            v-b-tooltip.hover
            v-if="data.item.is_ecommerce_product == 1"
            title="Aktif"
            class="mr-1 btn-light-success text-success"
            disabled
          ><i class="fas fa-check px-0"></i></b-button>
          <b-button
            size="sm"
            v-b-tooltip.hover
            v-if="data.item.is_ecommerce_product == 0"
            title="Non Aktif"
            class="mr-1 btn-light-danger text-danger"
            disabled
          ><i class="fas fa-times px-0"></i></b-button>
        </template>
      </template>
      <template #cell(sales_price)="data">
        {{ parseInt(data.item.sales_price).toLocaleString("id-ID") }}
      </template>
     
    </b-table>
   

    

  </div>
</template>

<script>
import XLSX from "xlsx";
import api from '@/core/services/api.service.js'
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {

  props: {
    purpose: String,
    selectedItem: Array,
    checkbox: Boolean,
    isHospitalTransaction: Boolean,
    dataModal:Array,
    excel:Array,
  },
   components: {
    
    XLSX,
   
  },

  data() {
    return {
      // Pagination
      // perPage: 20,
      // currentPage: 1,
      // totalRows: 1,
      // Filter
      filter: {
        name: "",
        medicine_category_id: "",
        medicine_packages_id:"",
      },
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          thClass: "nowrap",
          tdClass: "nowrap"
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap"
        },
        {
          key: "generic_name",
          label: "Nama Umum",
          sortable: true,
        },
        {
          key: "medicine_category_name",
          label: "Kategori",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap"
        },
        {
          key: "medicine_remaining_stock",
          label: "Sisa Stok",
          sortable: true
        },
        {
          key: "Batch",
          label: "Batch",
          sortable: true
        },
        {
          key: "uom_name",
          label: "Satuan",
          sortable: true
        },
        {
          key: "sales_price",
          label: "Harga Jual",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap"
        },
        // {
        //   key: "is_ecommerce_product",
        //   label: "E-commerce",
        //   sortable: true,
        //   thClass: "nowrap",
        //   tdClass: "nowrap"
        // },
        // {
        //   key: "actions",
        //   label: "Aksi",
        //   thClass: "nowrap",
        //   tdClass: "nowrap"
        // },
      ],
      // Table Items
      items: [],
      medicine_categories: [],
      medicine_packages: [],
      total_medicine: '',
      default_total_medicine: '',
      medicineItem:[],
      excelData:[],
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
    }
  },

  methods: {
    btnExcelOnClick() {
      
      const data = XLSX.utils.json_to_sheet(this.excelData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "Daftar Obat dan Alkes.xlsx");

      this.$bvModal.hide("modal-medicine-report");
    },

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.pagination()
    },

    filterByName() {
      this.pagination()
    },

    async filterByMedicineCategory(evt) {
      
      if (!evt) {
        this.filter.medicine_category_id = await ''
        this.pagination()
      } else {
        this.filter.medicine_category_id = await evt
        this.pagination()
        
      }
    },

    // async filterByMedicinePackage(evt) {
    
    //   if (!evt) {
    //     this.filter.medicine_package_id = await ''
    //     this.pagination()
    //   } else {
    //     this.filter.medicine_package_id = await evt
    //     this.pagination()
    
    //   }
    // },
    
    // async filterByMedicinePackage(evt) {
    //   let response = await module.get("medicine-packages/"+ await evt);
    //   this.medicineItem = response.display_medicine
    //   let datas = response.display_medicine
    //   this.setCheckedItemPackage()
    //   let f
    //   for(f=0;f<datas.length;f++){
    //     this.packageMedicine(datas[f])
    //   }
    
    
    // },

    async pagination() {
      // let route = "medicines"
      
      let filterParams = `&name=${this.filter.name}&medicine_category_id=${this.filter.medicine_category_id}`
      let response = await module.get(
          "medicine-no-paginations",
          `?${filterParams}`
        )
     
      let responseDatas = await module.get(
        "medicines-excel",
        `?${filterParams}`
      )

      // let test = await module.paginate(
      //   "medicine-expireds",
      //   `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      // )

      

      // this.total_medicine = response.meta.total_medicine
      // this.default_total_medicine = response.meta.total_medicine
      // let pagination = response.meta.pagination
      // this.totalRows = pagination.total

      // let c, b
      // b = this.perPage * (this.currentPage - 1) + 1
      // for (c = 0; c < response.data.length; c++) {
      //   response.data[c].number = b + c
      // }

      this.items = await response
      // this.excelData = await responseDatas
      this.excelData = this.excel
      // Coloring
      
      let a
      for (a = 0; a < this.items.length; a++) {
        
        if (this.items[a].medicine_remaining_stock < this.items[a].minimum_stock && this.items[a].medicine_remaining_stock != 0) {
          //  data[a].number = a + 1
          this.items[a]._rowVariant = 'warning'
          
        } else if (this.items[a].medicine_remaining_stock == 0) {
          this.items[a]._rowVariant = 'danger'
          
        }
      }
      this.getBatch()
      this.setCheckedItem()
    },

    setCheckedItem() {
      if(this.medicineItem.length == 0){
        let found
        this.items.forEach(value => {
          found = this.selectedItem.find(x => x.id == value.id)
          if (typeof found === 'undefined') {
            value.checked = false
          } else {
            value.checked = true
          }
        })
      }
    },

    

    chooseMedicine(value) {
      this.$emit("chooseMedicine", value);      
    },

    async packageMedicine(evt) {
      let response = await module.get("medicine-packages/"+ await evt);
      this.medicineItem = response.display_medicine
      
      let datas = response.display_medicine
      

      let found,a
      
      for(a=0;a<this.items.length;a++){
        found = this.medicineItem.find(x => x.id == this.items[a].id)
        if (typeof found === 'undefined') {
          this.items[a].checked = false
          
        } else {
          this.items[a].checked = true
          
        }
      }

      this.$emit("packageMedicine", datas);      
    },

    async setActive(id) {
      
      let datas = await module.get("medicines/"+id)
      
      
      if(datas["weight"] == 0){
        Swal.fire({
          icon: 'warning',
          title: 'Berat Barang Tidak Boleh Kosong, Berat Barang Digunakan Untuk Menghitung Ongkos Kirim E-Commerce',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        })
      }else{
        api.setHeader();
        let result = await api.put("medicines/" + id + "/set-active");
        
        if (result) {
          
          this.pagination();
        }
      }
      
    },

    async getMedicineCategoryOption() {
      let response = await module.setTreeSelect("medicine-categories");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.medicine_categories = response.data;
        this.medicine_categories.unshift({
          label: "Cari Berdasar Kategori",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getMedicinePackageOption() {
      let response = await module.setTreeSelect("medicine-packages");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.dataLoad = true;
        this.medicine_packages = response.data;
        this.medicine_packages.unshift({
          label: "Shortcut Obat dan Alkes",
          id: "",
          isDisabled: true,
        });
      }
    },

    async deleteData(id) {
      // Delete Data
      let result = await module.delete("medicines/" + id);
      // If Deleted
      if (result) {
        this.pagination();
      }
    },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      let a
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "9002") {
          this.btn = true
        }
      }
    },

    async getBatch() {
      // Batch
      let responseBatch = await module.get(
          "medicine-batch-stock-no-paginations"
        )
      let g
      
      
      this.items.forEach( (val,index) => {
        let batch = "<ul>"
        let find = responseBatch.filter(x => x.medicine_id === val.id)
        if(find.length > 0){
          find.forEach(val => {
            if(val.saldo > 0){
              if(batch == "<ul>"){
                batch = "<li>"+val.batch_no+"</li>"
              }else{
                batch = batch+"<li>"+val.batch_no+"</li>"
              }
              batch = batch+"</ul>"
            }
          })
        }
        
        this.items[index].batch = batch
        
        if(index == this.items.length-1){
          this.loadTable = true
        }
      })

      this.excelData.forEach( (val,index) => {
        let batch = ""
        let find = responseBatch.filter(x => x.medicine_id === val.id)
        if(find.length > 0){
          find.forEach(val => {
            if(val.saldo > 0){
              if(batch == ""){
                batch = val.batch_no
              }else{
                batch = batch+", "+val.batch_no
              }
            }
          })
        }
        
        this.excelData[index].batch = batch
        
        if(index == this.excelData.length-1){
          this.loadTable = true
        }
      })

    },

  },

  watch: {
    selectedItem: function (newVal) {
      this.setCheckedItem()
    }
  },

  mounted() {
    
    this.pagination()
    this.getMedicineCategoryOption()
    this.getMedicinePackageOption()
    this.setActiveButton()
  },

}

</script>

<style>
.th-list-medicines {
  vertical-align: middle !important;
}

.td-list-medicines {
  vertical-align: middle !important;
}

.nowrap{
  white-space: nowrap;
}
</style>