<template>
  <v-app>
    <div>

      <b-form @submit.stop.prevent="formOnsubmit">

        <!-- 

          STOCK OPNAME FIELD REQUIRED

         -->
        <!-- Input Birt Date -->
        <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >

          <v-dialog
            ref="dialog"
            v-model="modal_birt_date"
            :return-value.sync="form.checking_date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">

              <b-form-input
                readonly
                v-bind="attrs"
                v-on="on"
                id="input-birt-date"
                v-model="form.checking_date"
              >
              </b-form-input>
            </template>

            <v-date-picker
              locale="id"
              v-if="modal_birt_date"
              v-model="form.checking_date"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal_birt_date = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(form.checking_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Input employee -->
<!-- 
        <div class="row px-0">
          <div class="form-control"
             @click="$bvModal.show('modal-employee')"
          >
            {{display.employee_name}}
          </div>
        </div> -->
        <b-form-group
          id="input-group-trigger-modal-employee"
          label="Karyawan :"
          label-for="input-trigger-modal-employee"
        >
          <b-form-input
            id="input-trigger-modal-employee"
            v-model="display.employee_name"
            placeholder="Pilih karyawan"
            readonly
            @click="$bvModal.show('modal-employee')"
            
          >
          </b-form-input>
          <small class="text-danger">{{ error.employee_id }}</small>
        </b-form-group>

        <!-- 

          END STOCK OPNAME FIELD REQUIRED

         -->

         <!-- 

          DETAIL STOCK OPNAME FIELD REQUIRED

         -->

        <b-form-group
          id="input-group-trigger-modal-medicine"
          label="Obat & Alkes:"
          label-for="input-trigger-modal-medicine"
        >
          <b-form-input
            id="input-trigger-modal-medicine"
            v-model="display.medicine_name"
            placeholder="Pilih Obat"
            readonly
            @click="$bvModal.show('modal-medicine')"
          >
          </b-form-input>
          <small class="text-danger">{{ error.medicine_id }}</small>
        </b-form-group>

          <div class="bg-light" v-if="coll_medicine.length > 0">
            <table class="table " v-if="coll_medicine.length > 0">
              <tr>
                <th>Nama</th>
                <th>Stok Di Sistem</th>
                <th>Stok Fisik</th>
                <th>Selisih Stok</th>
                <th></th>
              </tr>
              <tr v-for="mdc in coll_medicine" :key="mdc.id">
                <td>{{ mdc.name }}</td>
                <td>{{ mdc.current_stock }}</td>
                <td>{{ mdc.checking_stock }}</td>
                <td>{{ mdc.difference_stock }}</td>
                <td>
                  <div class="row">
                    <b-button
                      size="sm"
                      class="btn-info mx-1"
                      v-b-tooltip.hover.left
                      title="update Obat dan Alkes"
                      type="button"
                      @click="updateMedicine(mdc.id)"
                      ><i class="fas fa-edit px-0"></i
                    ></b-button>
                    <b-button
                      size="sm"
                      class="btn-danger mx-1"
                      v-b-tooltip.hover.left
                      title="Hapus Obat dan Alkes"
                      type="button"
                      @click="removeMedicine(mdc.id)"
                      ><i class="fas fa-trash px-0"></i
                    ></b-button>

                  </div>
                </td>
              </tr>
            </table>
          </div>
        

        <!-- 

          END DETAIL STOCK OPNAME FIELD REQUIRED

         -->

        <b-button
          type="submit"
          variant="primary"
        >Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/stock-opnames/list')"
        >
          Batal
        </b-button>

      </b-form>

       <b-modal
        ref="my-modal"
        hide-footer
        size="lg"
        title="Stock Opname"
        @hide="hideModalCancel"
      >
        <b-form >
          <!-- <div class="d-block text-center"> -->
            <div class="row">
              
              <div class="col-md-12">
                <b-form-group
                  id="input-group-name"
                  label="Nama Item:"
                  label-for="input-name"
                >
                  <b-form-input
                    id="input-name"
                    v-model="detail_item.name"
                    placeholder="Nama Item"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-4">
                <b-form-group
                  id="input-group-name"
                  label="Stok Di Sistem:"
                  label-for="input-name"
                >
                  <b-form-input
                    id="input-name"
                    v-model="detail_item.current_stock"
                    placeholder="Stok Di Sistem"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-4">
                <b-form-group
                  id="input-group-name"
                  
                >
                  <label for="input-stock">Stok Fisik <span class="text-danger">*</span></label>
                  <b-form-input
                    id="input-stock"
                    v-model="detail_item.checking_stock"
                    placeholder="Stok Fisik"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-md-4">
                <b-form-group
                  id="input-group-name"
                  label="Selish Stok:"
                  label-for="input-name"
                >
                  <b-form-input
                    id="input-name"
                    v-model="detail_item.difference_stock"
                    placeholder="Selish Stok"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </div>
              
            </div>

         
          <b-button type="button" @click="medicineFormOnsubmit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="hideModalCancel"
          >
            Cancel
          </b-button>
        </b-form>
      </b-modal>
      
      <ModalEmployee
        @chosenEmployee="setEmployee"
        @submitedEmployee="setEmployee"
      />

       <ModalMedicine
        @chosenMedicine="setPrescription"
        @deleteMedicine="getItem"
        :selectedItem="coll_medicine"
        :checkbox="true"
        :isSortcut="true"
        :isAbjad="true"
      />

    </div>
  </v-app>
</template>

<script>
import ModalMedicine from "@/component/general/ModalMedicineStockOpnameEdit.vue";
import ModalEmployee from '@/component/general/ModalEmployee.vue'
import ApiService from "@/core/services/api.service.js";
import module from '@/core/modules/CrudModule.js'
import validation from '@/core/modules/ValidationModule.js'
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {

  props: {
    form: Object,
    route: String
  },

  components: {
    ModalEmployee,
    ModalMedicine,
  },

  data() {
    return {
      error: {
        name: "",
        blood_type_id: null,
        age: null,
        birt_date: null,
        last_donation_date: "",
        phone: "",
        gender: null,
        quantity: null,
        email: "",
        id_card_number: null,
        address: "",
      },
      modal_birt_date: false,

      display:{
        employee_name:"",
      },

      // medicines
      coll_medicine_form: {
        id: "",
        name: "",
        current_stock: "",
        checking_stock: "",
        difference_stock: "",
      },

      coll_medicine: [],
      coll_medicine_str: "",

      display: {
        medicine_name: "",
      },

      detail_item:{
        id:"",
        name:"",
        current_stock:0,
        checking_stock:"",
        difference_stock:"",
        // _method:"PUT"
      }
      
    }
  },

  methods: {

    setEmployee(value) {
      this.form.employee_id = value.id
      this.display.employee_name = value.name
      this.$bvModal.hide('modal-employee')
    },    

    async setPrescription(value) {
      console.log("value", value);
      await this.medicine_id;
      this.medicine_id = value.id;
      console.log("medicine_id", this.medicine_id);
      // this.coll_medicine_form.id = "";
      this.coll_medicine_form.name = "";
      this.coll_medicine_form.current_stock = "";
      this.coll_medicine_form.checking_stock = "";
      this.coll_medicine_form.difference_stock = "";
      this.setMedicine(value.id);
    },

    async setMedicine(id) {
      ApiService.get("medicines/" + id).then( async (response) => {
        console.log("medicines", response.data.data);
        this.medicine = response.data.data;
        this.name_medicine = response.data.data.name;
        this.coll_medicine_form.name = response.data.data.name;
        this.coll_medicine_form.medicine_id = response.data.data.id;
        this.coll_medicine_form.current_stock = response.data.data.stock;
        this.coll_medicine_form.checking_stock = 0;
        this.coll_medicine_form.difference_stock = 0;
        this.coll_medicine_form.stock_opname_id = this.$route.params.id;

        let res =  await ApiService.post('/detail-stock-opnames',this.coll_medicine_form )

         console.log(res);
        // Check Response
        if (res) {
          this.getItem()
          this.hideModalCancel()
        } 
        
        // this.$refs["my-modal"].show();
      });
    },

    displayMedicineName(medicine) {
      if (medicine.length == 0) {
        this.display.medicine_name = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.name;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.medicine_name = str;
      }
    },

    async removeMedicine($id) {
      console.log("remove batch", $id);
      let response = await module.delete(
        `detail-stock-opnames/${id}`
      );

      if (response) {
        this.getItem()
      }
    },

    async updateMedicine(id){
      let response = await module.get(
        `detail-stock-opnames/${id}`
      );

      this.detail_item.id = response.id
      this.detail_item.name = response.name
      this.detail_item.current_stock = response.current_stock

      this.$refs["my-modal"].show();
      console.log("dapet data",response);

    },

     async medicineFormOnsubmit() {

       let response =  await ApiService.put('/detail-stock-opnames/'+this.detail_item.id,this.detail_item )

       console.log(response);
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        this.getItem()
        Swal.fire("Berhasil","Update stok berhasil", 'success')
        this.hideModalCancel()
      }
     
      // this.coll_medicine_form.total_price =
      //   parseInt(this.coll_medicine_form.quantity) *
      //   parseInt(this.coll_medicine_form.sales_price);
      // this.coll_medicine_form.total_price = parseInt(
      //   this.coll_medicine_form.total_price
      // ).toLocaleString("id-ID");
      // this.coll_medicine_form.sales_price = parseInt(
      //   this.coll_medicine_form.sales_price
      // ).toLocaleString("id-ID");

      // this.medicine_collection_submit = true;
      // let clone = { ...this.coll_medicine_form };
      // this.coll_medicine.push(clone);

      // console.log("hasil", this.coll_medicine);
      // this.displayMedicineName(this.coll_medicine);
      // this.hideModal();
    },

     async hideModalCancel() {
      await this.$refs["my-modal"].hide();
    },

    setMedicineSubmit() {
      this.medicine_collection_submit = false;
    },
    
    hideModal() {
      this.$refs["my-modal"].hide();
    },

     getDisplayData(){
      if(this.form.employee_id != ""){
        this.display.employee_name = this.form.employee_name
        
      }

    },

    async formOnsubmit() {
      let medicine = JSON.stringify(this.coll_medicine)
      this.form.medicine = medicine
      this.form._method = "PUT"
      
      // Make Request
      let response = await module.submit(this.form, this.route)
      // Check Response
      if (response.state == 'error') {
        // Validation Error
        this.error = validation.setValidationError(this.error, response.error.errors)
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, 'success')
        
          this.detail_item.id = ""
          this.detail_item.name = ""
          this.detail_item.checking_stock = ""
          this.detail_item.difference_stock = ""
        
        
        this.$router.push('/stock-opnames')
      }
    },

    async getItem() {
      
      let response = await module.paginate(
        `detail-stock-opnames/by-stock/${this.$route.params.id}`,
        `?page=1&page_size=2000`
      );
      let pagination = response.meta.pagination
      this.totalRows = pagination.total
      this.coll_medicine = response.data;
    },
  

  },

   watch: {
    "detail_item.checking_stock": function (newVal) {
      let val = this.detail_item.checking_stock - this.detail_item.current_stock
      this.detail_item.difference_stock = val
    },
  },

  mounted() {
    this.getDisplayData()
    this.getItem()
    // this.getAge(new Date().toISOString().substr(0, 10))
  }

}
</script>

<style>
</style>